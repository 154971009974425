import { register } from 'register-service-worker';

/* tslint:disable:no-console */

const registrationInterval: number = Number(process.env.VUE_APP_REGISTRATION_INTERVAL) || 10;
if (process.env.NODE_ENV === 'production') {
	register(`./${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.log('App is being served from cache by a service worker. For more details, visit https://goo.gl/AFskqB');
		},
		registered(registration) {
			setInterval(() => {
				console.log('Checking for updated service worker...');
				registration.update();
			}, 1000 * 60 * registrationInterval);
			console.log('Service worker has been registered.');
		},
		cached() {
			console.log('Content has been cached for offline use.');
		},
		updatefound() {
			console.log('New content is downloading.');
		},
		updated(registration) {
			console.log('New content is available; requesting refresh in 15 secs.');
			setTimeout(() => {
				console.log('Sending content refresh event');
				document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }));
			}, 15000);
		},
		offline() {
			console.log('No internet connection found. App is running in offline mode.');
		},
		error(error) {
			console.error('Error during service worker registration:', error);
		},
	});
}
